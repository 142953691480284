import Vue from 'vue'
// import Vuetify from 'vuetify/lib/framework';
import Vuetify from 'vuetify/lib'
import Ripple from 'vuetify/lib/directives/ripple';
// import 'vuetify/dist/vuetify.min.css'

// import colors from 'vuetify/lib/util/colors'
import colors from 'vuetify/es5/util/colors'
import '@mdi/font/css/materialdesignicons.css'

// Vue.use(Vuetify)
Vue.use(Vuetify, {
  directives: {
    Ripple,
  },
});


const options = {
  icons: {
    iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    dark: true,
    // primary: colors2.purple,
    // secondary: colors2.grey.darken1,
    // accent: colors2.shades.black,
    // error: colors2.red.accent3,
    themes: {
      dark: {
        primary: '#3dabff', // VB blue
        secondary: '#6496c8', // VB Blue Secondary
        accent: colors.indigo.base, // #3F51B5
        info: '#125b95',
        error: '#733333',
        success: '#2e6f31',
        secondaryButtons: '#45555c',    // buttons on the second top bar
        sliderTrackColor: '#14171b',    // The color of the unfilled sliders
        podSidemenuButtons: "#2f3640",  // Pod Sidemenu top toggle buttons
        // error: colors.red.accent2,
        // success: colors.green.darken3,
      },
      light: {
        primary: '#3dabff', // VB blue
        secondary: '#6496c8', // VB Blue Secondary
        secondaryButtons: '#a6c4d3',    // buttons on the second top bar
        sliderTrackColor: '#e6ecf1',    // The color of the unfilled sliders
        podSidemenuButtons: "#e6ecf1",  // Pod Sidemenu top toggle buttons
        error: '#9f2e2e',
      }

      // options: {
      //   customProperties: true
      // },
    },
  }
}


export default new Vuetify(options);
